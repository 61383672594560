








import { defineComponent } from '@nuxtjs/composition-api'
import AppModalWindow from 'storybook/components/atoms/AppModalWindow.vue'

export default defineComponent({
  components: {
    AppModalWindow,
  },
  props: {
    message: {
      type: String,
      required: true,
    },
    isVisible: {
      type: Boolean,
      required: true,
    },
  },
})
