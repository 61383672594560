



























































































































import { ref, computed, defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  model: {
    prop: 'modelValue',
    event: 'update:modelValue',
  },
  props: {
    modelValue: {
      type: [String, Number],
      required: true,
    },
    error: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  setup(_, { emit }) {
    const handleInput = (e: InputEvent) => {
      const element = e.target as HTMLInputElement
      emit('update:modelValue', element.value)
    }

    const isShow = ref<Boolean>(false)

    const clickIcon = () => {
      isShow.value = !isShow.value
    }

    const inputType = computed(() => {
      return isShow.value ? 'text' : 'password'
    })

    return { handleInput, isShow, clickIcon, inputType }
  },
})
