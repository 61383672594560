




















import { defineComponent, ref } from '@nuxtjs/composition-api'
import InformationModal from '~/features/contracts/cleaning/baseSettings/components/InformationModal.vue'

export default defineComponent({
  components: {
    InformationModal,
  },
  props: {
    title: {
      type: String,
      required: false,
      default: '',
    },
    text: {
      type: String,
      required: false,
      default: '',
    },
    modalMessage: {
      type: String,
      required: false,
      default: '',
    },
  },
  setup() {
    const showModal = ref(false)
    return { showModal }
  },
})
