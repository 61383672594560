































































import {
  defineComponent,
  reactive,
  ref,
  useContext,
  watch,
} from '@nuxtjs/composition-api'
import AppButton from './AppButton.vue'
import AppInputText from './AppInputText.vue'
import AppInputPassword from '@/components/AppInputPassword.vue'
import TestEnvironmentNotice from '~/components/TestEnvironmentNotice.vue'
import IconHeaderLogo from '~/components/icons/IconHeaderLogo.vue'
import { useAuth } from '@/composable/api/useAuth'
import { isAxiosError } from '~/utils/exception'
import DateTime from '~/utils/dateTime'
import { getEnv } from '~/utils/utils'

export default defineComponent({
  components: {
    AppInputText,
    AppInputPassword,
    AppButton,
    TestEnvironmentNotice,
    IconHeaderLogo,
  },
  props: {
    shopName: {
      type: String,
      required: false,
      default: '',
    },
  },
  emits: ['handleChangeShop'],
  setup(props, { emit }) {
    const { $config } = useContext()
    const environmentType = getEnv($config.BASE_URL)
    // NOTE: パスワードリセット機能が実装されるまでの処理（API準備が整うまでの暫定対応）
    const isProduction = process.env.NODE_ENV === 'production'
    const passwordResetUrl = new DateTime().isBefore('2023-07-04')
      ? 'https://production.wedding-box-crm.com/crm/password/reset'
      : 'https://wedding-box-crm.com/crm/password/reset'

    const displayedUserEmail = ref('')
    const user = reactive({
      email: '',
      password: '',
    })

    const { checkLogin } = useAuth()

    const errors = reactive({
      email: '',
      password: '',
      shop: '',
    })

    const loginEvent = async () => {
      if (!displayedUserEmail.value || !user.password || !props.shopName) {
        errors.email = !user.email ? 'メールアドレスを入力してください' : ''
        errors.password = !user.password ? 'パスワードを入力してください' : ''
        errors.shop = !props.shopName ? '店舗を選択してください' : ''
        return
      }

      errors.email = ''
      errors.password = ''
      errors.shop = ''

      try {
        emit('toggleLoading')

        // ＠以降ががない場合はドメイン名を追加する
        if (!displayedUserEmail.value.includes('@')) {
          user.email = displayedUserEmail.value + '@weddingbox.co.jp'
        } else {
          // ＠が含まれる場合はそのまま送る（開発者アカウントでもログイン可能にするため）
          user.email = displayedUserEmail.value
        }

        await checkLogin(user)
        emit('openSmsModal', user)
      } catch (error) {
        if (isAxiosError(error) && error.response?.status === 403) {
          alert(
            'アカウントがロックされました。システム管理者にご連絡ください。'
          )
          emit('toggleLoading')
          return
        }
        alert(
          '[ログインエラー] ログインに失敗しました。アカウント情報をご確認ください。'
        )
      }
      emit('toggleLoading')
    }

    const handleChangeShop = () => emit('handleChangeShop')

    watch(props, () => {
      errors.email = ''
      errors.password = ''
      errors.shop = ''
    })

    return {
      user,
      displayedUserEmail,
      errors,
      loginEvent,
      handleChangeShop,
      isProduction,
      passwordResetUrl,
      environmentType,
    }
  },
})
