

























import { defineComponent, PropType, ref } from '@nuxtjs/composition-api'
import { DeleteOption } from '~/components/_graduationCeremony/atoms/AppInputText.vue'
import DeleteIcon from 'storybook/components/icon/DeleteIcon.vue'

export default defineComponent({
  components: {
    DeleteIcon,
  },
  model: {
    prop: 'modelValue',
    event: 'update:modelValue',
  },
  props: {
    modelValue: {
      type: [String, Number],
      default: '',
    },
    error: {
      type: Boolean,
      required: false,
      default: false,
    },
    type: {
      type: String,
      required: false,
      default: 'text',
    },
    placeholder: {
      type: String,
      required: false,
      default: '',
    },
    spellcheck: {
      type: Boolean,
      required: false,
      default: false,
    },
    autocomplete: {
      type: String,
      required: false,
      default: 'on',
    },
    maxlength: {
      type: String,
      required: false,
      default: null,
    },
    isDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    inputMode: {
      type: String,
      required: false,
      default: '',
    },
    deleteOption: {
      type: Object as PropType<DeleteOption>,
      default: () => ({
        canDelete: false,
      }),
    },
    hasDomain: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const handleInput = (e: InputEvent) => {
      const element = e.target as HTMLInputElement
      emit('update:modelValue', element.value)
    }

    const deleteInput = () => {
      emit('update:modelValue', '')
    }

    const maxlengthControlForNumber = `javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);`

    return { handleInput, deleteInput, maxlengthControlForNumber }
  },
})
