




















import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    isValidateError: {
      type: Boolean,
      default: false,
    },
    isFocus: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      width: 16,
      height: 16,
    }
  },
})
